exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-vi-js": () => import("./../../../src/pages/about.vi.js" /* webpackChunkName: "component---src-pages-about-vi-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-vi-js": () => import("./../../../src/pages/contact.vi.js" /* webpackChunkName: "component---src-pages-contact-vi-js" */),
  "component---src-pages-how-we-do-js": () => import("./../../../src/pages/how-we-do.js" /* webpackChunkName: "component---src-pages-how-we-do-js" */),
  "component---src-pages-how-we-do-vi-js": () => import("./../../../src/pages/how-we-do.vi.js" /* webpackChunkName: "component---src-pages-how-we-do-vi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-vi-js": () => import("./../../../src/pages/index.vi.js" /* webpackChunkName: "component---src-pages-index-vi-js" */),
  "component---src-pages-projects-duplex-js": () => import("./../../../src/pages/projects/duplex.js" /* webpackChunkName: "component---src-pages-projects-duplex-js" */),
  "component---src-pages-projects-duplex-vi-js": () => import("./../../../src/pages/projects/duplex.vi.js" /* webpackChunkName: "component---src-pages-projects-duplex-vi-js" */),
  "component---src-pages-projects-granny-js": () => import("./../../../src/pages/projects/granny.js" /* webpackChunkName: "component---src-pages-projects-granny-js" */),
  "component---src-pages-projects-granny-vi-js": () => import("./../../../src/pages/projects/granny.vi.js" /* webpackChunkName: "component---src-pages-projects-granny-vi-js" */),
  "component---src-pages-projects-house-js": () => import("./../../../src/pages/projects/house.js" /* webpackChunkName: "component---src-pages-projects-house-js" */),
  "component---src-pages-projects-house-vi-js": () => import("./../../../src/pages/projects/house.vi.js" /* webpackChunkName: "component---src-pages-projects-house-vi-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-index-vi-js": () => import("./../../../src/pages/projects/index.vi.js" /* webpackChunkName: "component---src-pages-projects-index-vi-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-vi-js": () => import("./../../../src/pages/services.vi.js" /* webpackChunkName: "component---src-pages-services-vi-js" */)
}

